/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    'train-front-fill': {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M10.621.515C8.647.02 7.353.02 5.38.515c-.924.23-1.982.766-2.78 1.22C1.566 2.322 1 3.432 1 4.582V13.5A2.5 2.5 0 003.5 16h9a2.5 2.5 0 002.5-2.5V4.583c0-1.15-.565-2.26-1.6-2.849-.797-.453-1.855-.988-2.779-1.22zM6.5 2h3a.5.5 0 010 1h-3a.5.5 0 010-1m-2 2h7A1.5 1.5 0 0113 5.5v2A1.5 1.5 0 0111.5 9h-7A1.5 1.5 0 013 7.5v-2A1.5 1.5 0 014.5 4m.5 9a1 1 0 11-2 0 1 1 0 012 0m0 0a1 1 0 112 0 1 1 0 01-2 0m8 0a1 1 0 11-2 0 1 1 0 012 0m-3-1a1 1 0 110 2 1 1 0 010-2M4 5.5a.5.5 0 01.5-.5h3v3h-3a.5.5 0 01-.5-.5zM8.5 8V5h3a.5.5 0 01.5.5v2a.5.5 0 01-.5.5z"/>',
    },
});
